<template>
  <div>
    <ProDocumentBreadcrumbs :breadcrumbs="breadcrumbs" user-role="procurer" />
    <ProReport
      title="Document.SearchEmailTemplateList"
      :columns="columns"
      v-model="emailTemplateList"
      row-key="id"
      auto-search
      selectable
      show-columns-setting
      :loading="loading"
    >
      <template #selectedBody="{ row }">
        <q-card class="q-ml-md">
          <q-card-section>
            <div v-html="row.body" />
          </q-card-section>
        </q-card>
      </template>
    </ProReport>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs.vue";
import ProReport from "@/components/PROSmart/Report/ProReport";
export default {
  name: "EmailTemplate",
  components: { ProDocumentBreadcrumbs, ProReport },

  data() {
    return {
      emailTemplateList: [],
      loading: false,
    };
  },

  mounted() {
    this.getEmailTemplateList();
  },

  methods: {
    getEmailTemplateList() {
      this.loading = true;
      this.$proSmart.admin
        .GetEmailTemplateInfo(this)
        .then((res) => {
          this.emailTemplateList = res.map((item, index) => {
            this.findSubjectOrBody(item);
            return {
              id: index + 1,
              ...item,
            };
          });
        })
        .finally(() => (this.loading = false));
    },
    findSubjectOrBody(item) {
      if (item.remark && !item.subject) {
        const { templateOption } = JSON.parse(item.remark);
        if (templateOption.length > 0) {
          const subArr = [],
            bodyArr = [];
          templateOption.forEach((temp) => {
            subArr.push(temp.value.subject);
            bodyArr.push(temp.value.body);
          });
          item.subject = subArr.join(" / ");
          item.body = bodyArr.join("<br /><br />");
        }
      }
    },
  },

  computed: {
    breadcrumbs() {
      return [{ name: "menu.EmailTemplateList", icon: "PROSmart-Docs" }];
    },
    columns() {
      return [
        {
          name: "moduleName",
          label: this.getRes("CustMenu.Field.ModuleName"),
          field: "moduleName",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
        },
        {
          name: "workflowCode",
          label: this.getRes("CustMenu.Field.WorkflowCode"),
          field: "workflowCode",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
        },
        {
          name: "workflowName",
          label: this.getRes("CustMenu.Field.WorkflowName"),
          field: "workflowName",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
        },
        {
          name: "keyName",
          label: this.getRes("Form.Option.Key"),
          field: "keyName",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
        },
        {
          name: "subject",
          label: this.getRes("CustMenu.Field.Subject"),
          field: "subject",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
        },
        {
          name: "formatBody",
          label: this.getRes("Form.Field.Format"),
          field: "formatBody",
          align: "left",
          type: "string",
          defaultShow: false,
        },
        {
          name: "body",
          label: this.getRes("Form.Section.EmailTemplate"),
          field: "body",
          align: "left",
          type: "string",
          defaultShow: false,
        },
        {
          name: "remark",
          label: this.getRes("CustMenu.Field.Remark"),
          field: "remark",
          align: "left",
          type: "string",
          defaultShow: false,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ {
  th,
  td {
    vertical-align: middle;
  }
}
</style>
